<script lang="ts" setup>
import EnvironmentDebug from './environment-debug.vue';
import LocaleDebug from './locale-debug.vue';
import RegionDebug from './region-debug.vue';
import FeatureFlagDebug from './feature-flag-debug.vue';
import ModalDebug from './modal-debug.vue';
import Button from '../design-system/button.vue';
import { CogIcon, XMarkIcon } from '@heroicons/vue/24/outline';

const open = ref<boolean>(false);

const isDev = computed(() => import.meta.dev);

const tools = [
  {
    title: 'Locale',
    enabled: true,
    component: LocaleDebug,
  },
  {
    title: 'Region',
    enabled: isDev.value,
    component: RegionDebug,
  },
  {
    title: 'Environment',
    enabled: isDev.value,
    component: EnvironmentDebug,
  },
  {
    title: 'Feature Flags',
    enabled: true,
    component: FeatureFlagDebug,
  },
  {
    title: 'Modals',
    enabled: true,
    component: ModalDebug,
  },
];
</script>
<template>
  <div>
    <div
      class="fixed bottom-4 right-0 p-2 bg-primary-layer-alternative flex gap-2 justify-between std-border"
      :class="[
        { 'w-[calc(100vw-16px)] mx-2 rounded-lg': open, 'rounded-l-lg': !open },
      ]"
      :style="{ zIndex: 100 }"
    >
      <div v-if="open" class="flex gap-2">
        <div
          v-for="(tool, index) in tools.filter(({ enabled }) => enabled)"
          :key="index"
          class="pr-2 border-r"
        >
          <div class="text-xs text-base">{{ tool.title }}:</div>
          <component :is="tool.component" :key="tool.title" />
        </div>
      </div>
      <div>
        <div v-if="!open" class="text-sm font-bold">Debug tools</div>
        <div v-if="!open" class="text-sm font-bold text-center">
          {{ isDev ? 'Local' : 'Deployed' }}
        </div>
        <Button
          type="tertiary-alternative"
          class="mx-auto"
          @click="open = !open"
        >
          <CogIcon
            v-if="!open"
            :key="`${open}-cog`"
            class="hover:animate-spin"
          />
          <XMarkIcon v-else :key="`${open}-xmark`" />
        </Button>
      </div>
    </div>
  </div>
</template>
