<script setup lang="ts">
import Button from '../design-system/button.vue';
import Drawer from 'primevue/drawer';
import ToggleSwitch from 'primevue/toggleswitch';

interface IFeatureFlags {
  features: IFeatures[];
  description: string;
  key: string;
  name: string;
}

interface IFeatures {
  description: string;
  enabled: boolean;
  id: number;
  key: string;
  name: string;
}

const active = ref<boolean>(false);
const changed = ref<boolean>(false);
const siteStore = useSiteStore();

const originalFeatureFlags = ref<Map<string, boolean>>(new Map());
const featureFlags = computed<IFeatureFlags[]>(
  () => siteStore.getConfig.featureFlags
);

function reset() {
  siteStore.featureFlags = originalFeatureFlags.value;
  originalFeatureFlags.value.forEach((v, k) => {
    siteStore.getFeatureFlags.set(k, v);
  });
  changed.value = false;
}

watchEffect(() => {
  if (siteStore.getFeatureFlags.size > 0)
    siteStore.getFeatureFlags.forEach((v, k) => {
      originalFeatureFlags.value.set(k, v);
    });
});

function sortFeatureFlags(ff: IFeatureFlags[]) {
  const sortedFF: IFeatureFlags[] = ff;
  sortedFF.forEach((featureGroup) => {
    featureGroup.features.sort((a, b) => a.key.localeCompare(b.key));
  });

  featureFlags.value = sortedFF;
}
</script>
<template>
  <div class="flex gap-2">
    <Button
      class="font-normal std-border"
      type="tertiary"
      padding="md"
      rounding="md"
      @click="active = true"
    >
      Toggle feature flags
    </Button>
    <Drawer
      v-model:visible="active"
      header="Feature flags"
      position="bottom"
      class="text-base p-4 overflow-auto"
    >
      <template #header>
        <div class="flex gap-3">
          <p class="text-base-priority text-lg font-bold">Feature Flags</p>

          <Button
            type="neutral"
            padding="wide-short"
            rounding="md"
            @click="sortFeatureFlags(featureFlags)"
          >
            Sort
          </Button>
        </div>
      </template>
      <div
        class="text-base grid grid-cols-5 gap-2 max-h-[60vh] overflow-scroll"
      >
        <div
          v-for="({ name, key, features }, index) in featureFlags"
          :key="`ff-${index}`"
          class="bg-primary-layer p-2 rounded-lg"
        >
          <div class="font-bold">{{ name }}</div>
          <hr />
          <div
            v-for="({ name: ffName, key: ffKey }, fIndex) in features"
            :key="`ff-${fIndex}-${index}`"
            class="flex justify-between mb-1"
          >
            <div class="truncate w-9/12">
              {{ ffName }}
            </div>
            <ToggleSwitch
              :model-value="
                siteStore.featureFlags.get(`${key}.${ffKey}`.toLowerCase())
              "
              @change="
                [
                  siteStore.featureFlags.set(
                    `${key}.${ffKey}`.toLowerCase(),
                    !siteStore.featureFlags.get(`${key}.${ffKey}`.toLowerCase())
                  ),
                  (changed = true),
                ]
              "
            />
          </div>
        </div>
      </div>
    </Drawer>
    <!-- <Button
      v-if="changed"
      type="neutral"
      padding="md"
      rounding="md"
      @click="reset()"
    >
      Reset
    </Button> -->
  </div>
</template>
